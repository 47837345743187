"use strict";

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _braftConvert = require("braft-convert");

var _constants = require("@meepshop/apollo/lib/constants");

// import
// definition
var useHtml = function useHtml(value) {
  return !value ? '' : (0, _braftConvert.convertRawToHTML)(value, {
    fontFamilies: _constants.FONTFAMILY.map(function (font) {
      return {
        name: font,
        family: font
      };
    })
  }).replace(/<p(| style="[^>]*")><\/p>/g, '<br />');
};

var _default = useHtml;
exports["default"] = _default;